<template>
<Navbar />
    <section class="courses-details-area pt-100 pb-70">
        <div class="container" v-if="!isLoading">
            <div class="courses-details-header">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-9">
                        <div class="courses-title pb-70">
                            <h2>{{ course.title }}</h2>
                            <h3>{{ chapter.title }} Quizes</h3>
                        </div>
                        <div v-if="quizes.length">
                            <ul class="list-group" v-for="quiz in quizes" :key="quiz.id">
                                <li class="list-group-item d-flex justify-content-between align-items-start">
                                    <div class="ms-2 me-auto">
                                        <div class="fw-bold">
                                            {{ quiz.title }} 
                                        </div>
                                    </div>
                                    <span class="badge text-default rounded-pill">
                                    <router-link  class="btn default-btn" :to="{ name: 'CourseQuizDetails', params: {id: quiz.id}}">Take This Quiz</router-link>
                                    </span>
                                </li>
                            </ul>
                            <div class="text-center">
                                <div v-if="lesson == 0">
                                    <router-link  class="btn default-btn" :to="{ name: 'CourseDetails', params: {id: chapter.course_id}}">To Course</router-link>
                                </div>
                                <div v-else>
                                    <router-link  class="btn default-btn" :to="{ name: 'CourseLesson', params: {id: lesson.id}}">Continue Next Chapter Lessons</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" v-else>
                            <h3>This Chapter Has No Quiz</h3>
                            <div v-if="lesson == 0">
                                <router-link  class="btn default-btn" :to="{ name: 'CourseDetails', params: {id: chapter.course_id}}">To Course</router-link>
                            </div>
                            <div v-else>
                                <router-link  class="btn default-btn" :to="{ name: 'CourseLesson', params: {id: lesson.id}}">Continue Next Chapter Lessons</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <Profile :course="course" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <Loading />
        </div>
    </section>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import Profile from '@/components/trainer/Profile.vue'
export default {
 components: { Navbar, Loading,Profile, },
    data(){
        return {
            rating: 4.3,
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            user:{},
            quizes: {},
            course: {},
            chapter:{},
            lesson:{},
        }
    },
    methods:{
        nextLesson(){
            let id = this.$route.params.id
            axios.post('https://apitraining.vipawaworks.com/api/lesson/get_next_lesson/' + id).then(response => {
                this.lesson = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getChapter(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course_content/profile/' + id).then(response => {
                this.chapter = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getCourse(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
                this.course = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        getCourseQuiz(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/quiz/get_quiz_with_questions/'+ id).then(response => {
                this.quizes = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
                }
            })
        },
    },
    created(){
        document.title = `Course Lessons - Training`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getCourseQuiz()
        this.getCourse()
        this.getChapter()
        this.nextLesson()
    }
}
</script>

<style scoped>
.card-img-top{
    height: 100px;
    width: 100px;
}
</style>